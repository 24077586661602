
  import { computed, defineComponent, onMounted, ref, watch } from 'vue';
  import { ErrorMessage, Field, Form } from 'vee-validate';
  import { setCurrentPageBreadcrumbs } from '@/core/helpers/breadcrumb';
  import { useI18n } from 'vue-i18n/index';
  import { useStore } from 'vuex';
  import { useRouter, useRoute } from 'vue-router';
  import { Actions } from '@/store/enums/StoreEnums';
  import { useAbility } from '@casl/vue';
  import Swal from 'sweetalert2/dist/sweetalert2.js';
  import * as Yup from 'yup';
  import { reinitializeComponents } from '@/core/plugins/keenthemes';
  import Lightgallery from 'lightgallery/vue';
  import lgZoom from 'lightgallery/plugins/zoom';
  import lgVideo from 'lightgallery/plugins/video';
  import Multiselect from '@vueform/multiselect';

  let lightGallery;
  let lightGalleryDoc;
  //let thumbnailImages;
  const thumbnailImages = ref([]);

  export default defineComponent({
    name: 'ads-creating',
    components: {
      ErrorMessage,
      Field,
      Form,
      Lightgallery,
      Multiselect,
    },
    data() {
      return {
        // thumbnailImages: [],
        plugins: [lgZoom, lgVideo],
      };
    },
    methods: {
      previewImages(e) {
        const files = Array.from(e.target.files);
        // this.thumbnailImages = [];

        const compressAndAddImage = async (file) => {
          try {
            const compressedImage = await compressImage(file, 800, 800, 0.8);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            const imageURL = URL.createObjectURL(compressedImage);
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            //@ts-ignore
            this.thumbnailImages.push(imageURL);
          } catch (error) {
            console.error('Error compressing image:', error);
          }
        };
        const compressImage = (file, maxWidth, maxHeight, quality) => {
          return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.onload = function (event) {
              const image = new Image();
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              image.src = event.target.result;
              image.onload = function () {
                let canvas = document.createElement('canvas');
                let width = image.width;
                let height = image.height;
                if (width > height) {
                  if (width > maxWidth) {
                    height *= maxWidth / width;
                    width = maxWidth;
                  }
                } else {
                  if (height > maxHeight) {
                    width *= maxHeight / height;
                    height = maxHeight;
                  }
                }
                canvas.width = width;
                canvas.height = height;
                let ctx = canvas.getContext('2d');
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                //@ts-ignore
                ctx.drawImage(image, 0, 0, width, height);
                canvas.toBlob(
                  function (compressed) {
                    resolve(compressed);
                  },
                  'image/jpeg',
                  quality
                );
              };
              image.onerror = function (error) {
                reject(error);
              };
            };
            reader.onerror = function (error) {
              reject(error);
            };
            reader.readAsDataURL(file);
          });
        };

        files.slice(0, 10).forEach((file) => {
          compressAndAddImage(file);
        });
      },
      onBeforeSlide: () => {
        console.log('calling before slide');
      },
    },
    async setup() {
      const { t, te } = useI18n();
      const { can } = useAbility();
      const store = useStore();
      const router = useRouter();
      const route = useRoute();
      const submitButton = ref<HTMLButtonElement | null>(null);
      const submitVideoButton = ref<HTMLButtonElement | null>(null);
      const submitImagesButton = ref<HTMLButtonElement | null>(null);
      const submitDocButton = ref<HTMLButtonElement | null>(null);
      const loading = ref(false);

      const selectedArea = ref<number>(0);
      const selectedAdTypesId = ref<number>(0);
      const selectedPropertyCategoryId = ref<number>(0);
      const selectedPropertyTypesId = ref<number>(0);
      const selectedUser = ref<number>(0);
      const videoInput = ref('');
      const docInput = ref('');
      const guideVideo = ref('');
      const adsPdf = ref('');
      const imagesInput = ref('');
      const certifiedCheckRequest = ref(false);
      const loanRequest = ref(false);
      const appUsers = ref<any>([]);
      const propertyTypesCategories = ref<any>([]);
      const propertyTypes = ref<any>([]);
      const publishName = ref('');
      const price = ref<number>(0);
      const priceVal = ref<number>(0);
      const adDetails = ref<string | null>(null);
      const size = ref<number>(0);
      const publishMobile = ref<string>('');
      const packageId = ref<number>(0);
      const numRooms = ref<number>(0);
      const numBathrooms = ref<number>(0);
      const thumbnail = ref('/media/avatars/upload.png');

      onMounted(() => {
        setCurrentPageBreadcrumbs(translate('adsUpdate'), [translate('ads')]);
        reinitializeComponents();
      });
      const removeImage = async (id, index) => {
        await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');

        await store.dispatch(Actions.DELETE_AD_DOCUMENT, id);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];
        if (error) {
          await store.dispatch(
            Actions.REMOVE_BODY_CLASSNAME,
            'page-loading-over'
          );
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
        } else {
          await store.dispatch(
            Actions.REMOVE_BODY_CLASSNAME,
            'page-loading-over'
          );
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;

          thumbnailImages.value.splice(index, 1);
          Swal.fire({
            text: translate('SUCCESSFULLY_REMOVE_AD_IMAGE'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          });
        }
      };
      const getAttachmentName = (item, index) => {
        return `${item.type.replace(/([A-Z])/g, ' $1')} - ${index + 1}`;
      };
      const downloadAttachment = (item) => {
        window.open(item.name, '_blank');
      };

      watch(
        () => selectedAdTypesId.value,
        async () => {
          await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');
          if (selectedAdTypesId.value) {
            await store.dispatch(
              Actions.GET_ALL_PROPERTY_CATEGORIES_BY_AD_TYPE_ID,
              { adType: selectedAdTypesId.value }
            );
            propertyTypesCategories.value =
              store.getters.propertyAllCategoryList;
            // selectedPropertyTypesId.value = 0;
            // selectedPropertyCategoryId.value = 0;
            if (
              Number(selectedAdTypesId.value) != 3 ||
              Number(selectedAdTypesId.value) == 4
            ) {
              numBathrooms.value = 0;
              numRooms.value = 0;
            }
            if (propertyTypesCategories.value.length <= 0) {
              propertyTypesCategories.value = [];
              propertyTypes.value = [];
              await store.dispatch(
                Actions.REMOVE_BODY_CLASSNAME,
                'page-loading-over'
              );
            }
            await store.dispatch(
              Actions.REMOVE_BODY_CLASSNAME,
              'page-loading-over'
            );
          }
        }
      );

      watch(
        () => selectedPropertyCategoryId.value,
        async () => {
          await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');
          if (selectedPropertyCategoryId.value) {
            await store.dispatch(Actions.GET_ALL_PROPERTY_TYPE, {
              type: 'ads',
              propertyTypesCategoryId: selectedPropertyCategoryId.value,
            });
            propertyTypes.value = store.getters.propertyTypesAllList;
            if (
              ad.value?.propertyCategoryId != selectedPropertyCategoryId.value
            )
              selectedPropertyTypesId.value = 0;

            await store.dispatch(
              Actions.REMOVE_BODY_CLASSNAME,
              'page-loading-over'
            );
          }
        }
      );

      watch(
        () => selectedUser.value,
        async () => {
          if (selectedUser.value) {
            const userObj = appUsers.value.value.filter(
              (user) => user.id == selectedUser.value
            )[0];

            if (userObj.name == publishName.value)
              publishName.value = userObj.name;
            else publishName.value = ad.value.publishName;

            if (userObj.phone == publishMobile.value)
              publishMobile.value = userObj.phone;
            else publishMobile.value = ad.value.publishMobile;
          }
        }
      );

      watch(
        () => priceVal.value,
        async () => {
          // if (priceVal.value < 0) price.value = 0;
        }
      );

      watch(videoInput, (newVideoInput, oldVideoInput) => {
        if (!newVideoInput) {
          videoInput.value = oldVideoInput;
        }
      });
      const { data } = await store.dispatch(Actions.GET_AD, route.params.id);
      await store.dispatch(Actions.GET_ALL_APP_USERS);
      appUsers.value = computed(() => store.getters.usersAllList);

      const ad = ref<any>(data.data);
      selectedUser.value = ad.value.memberId;
      publishName.value = ad.value.publishName;
      selectedPropertyCategoryId.value = ad.value.propertyCategoryId;
      selectedPropertyTypesId.value = ad.value.propertyTypeId;
      selectedAdTypesId.value = ad.value.categoryId;
      selectedArea.value = ad.value.areaId;
      // debugger;
      if (priceVal.value < 0) price.value = 0;
      // debugger;
      // console.log(price.value);

      // await store.dispatch(Actions.GET_ALL_PROPERTY_CATEGORIES_BY_AD_TYPE_ID, {
      //   adType: selectedAdTypesId.value,
      // });
      // await store.dispatch(Actions.GET_ALL_PROPERTY_TYPE, {
      //   type: 'ads',
      //   propertyTypesCategoryId: selectedPropertyCategoryId.value,
      // });
      propertyTypesCategories.value = store.getters.propertyAllCategoryList;

      if (ad.value.thumbnail && ad.value.thumbnail.image)
        thumbnailImages.value = ad.value.thumbnail.image.map((image) => ({
          id: image.id,
          name: image.name,
        }));
      else thumbnailImages.value = [];
      priceVal.value = ad.value['price'] <= 0 ? ad.value['price'] : 0;
      if (ad.value['price'] > 0) price.value = ad.value['price'];
      const translate = (text) => {
        if (te(text)) {
          return t(text);
        } else {
          return text;
        }
      };

      const goBack = () => {
        router.back();
      };

      const adsSchema = Yup.object().shape({
        adTypesId: Yup.number()
          .notOneOf([0], () => translate('AD_TYPE__IS_REQUIRED_FIELD'))
          .required(() => translate('AD_TYPE_IS_REQUIRED_FIELD'))
          .label('Ad type'),
        publishName: Yup.string()
          .required(() => translate('PUBLISH_NAME_IS_REQUIRED_FIELD'))
          .label('publish Name'),
        size: Yup.number()
          .required(() => translate('SIZE_IS_REQUIRED_FIELD'))
          .label('size'),
        price: Yup.number()
          .moreThan(-1, 'PRICE_IS_REQUIRED_FIELD')
          .label('price'),
        packageId: Yup.number()
          .notOneOf([0], () => translate('PACKAGE_IS_REQUIRED_FIELD'))
          .required(() => translate('PACKAGE_IS_REQUIRED_FIELD'))
          .label('packageId'),
        // propertyCategoryId: Yup.number()
        //   .notOneOf([0], () => translate('PROPERTY_CATEGORY_IS_REQUIRED_FIELD'))
        //   .required(() => translate('PROPERTY_CATEGORY_IS_REQUIRED_FIELD'))
        //   .label('propertyCategoryId'),
        // propertyTypeId: Yup.number()
        //   .notOneOf([0], () => translate('PROPERTY_TYPE_IS_REQUIRED_FIELD'))
        //   .required(() => translate('PROPERTY_TYPE_IS_REQUIRED_FIELD'))
        //   .label('propertyTypeId'),
        propertyCategoryId: Yup.number().when('adTypesId', (adTypesId, field) =>
          adTypesId !== 5 && adTypesId > 0
            ? field
                .notOneOf([0], 'PROPERTY_CATEGORY_IS_REQUIRED_FIELD')
                .required('PROPERTY_CATEGORY_IS_REQUIRED_FIELD')
                .label('propertyCategoryId')
            : field
        ),
        propertyTypeId: Yup.number().when('adTypesId', (adTypesId, field) =>
          adTypesId !== 5 && adTypesId > 0
            ? field
                .notOneOf([0], () =>
                  translate('PROPERTY_TYPE_IS_REQUIRED_FIELD')
                )
                .required('PROPERTY_TYPE_IS_REQUIRED_FIELD')
                .label('propertyTypeId')
            : field
        ),
      });

      const onSubmitUpdate = async (values) => {
        await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');
        if (submitButton.value) {
          // eslint-disable-next-line
          submitButton.value!.disabled = true;
          submitButton.value.setAttribute('data-kt-indicator', 'on');
        }

        let payload = {
          id: route.params.id,
          data: {
            publishName: publishName.value,
            price:
              Number(priceVal.value) == 0
                ? Number(price.value)
                : Number(priceVal.value),
            size: Number(values.size),
            memberId: Number(selectedUser.value),
            publishMobile: publishMobile.value,
            packageId: Number(values.packageId),
            areaId: Number(selectedArea.value),
            propertyTypeId: Number(selectedPropertyTypesId.value)
              ? Number(selectedPropertyTypesId.value)
              : undefined,
            propertyCategoryId: Number(selectedPropertyCategoryId.value)
              ? Number(selectedPropertyCategoryId.value)
              : undefined,
            numRooms: Number(values.numRooms)
              ? Number(values.numRooms)
              : undefined,
            numBathrooms: Number(values.numBathrooms)
              ? Number(values.numBathrooms)
              : undefined,
            additionalInfo: values.adDetails ? values.adDetails : '',
          },
        };

        // if (Number(selectedPropertyCategoryId.value))
        //   payload['propertyTypeId'] = Number(values.selectedPropertyTypesId);
        // if (Number(selectedPropertyCategoryId.value))
        //   payload['propertyCategoryId'] = Number(
        //     selectedPropertyCategoryId.value
        //   );

        // if (Number(values.numRooms))
        //   payload['numRooms'] = Number(values.numRooms);
        // if (Number(values.numBathrooms))
        //   payload['numBathrooms'] = Number(values.numBathrooms);
        // if (values.adDetails) payload['additionalInfo'] = values.additionalInfo;

        await store.dispatch(Actions.UPDATE_AD, payload);
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          await store.dispatch(
            Actions.REMOVE_BODY_CLASSNAME,
            'page-loading-over'
          );
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;
        } else {
          await store.dispatch(
            Actions.REMOVE_BODY_CLASSNAME,
            'page-loading-over'
          );
          //Deactivate indicator
          submitButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitButton.value!.disabled = false;

          Swal.fire({
            text: translate('SUCCESSFULLY_UPDATED_AD'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            router.push({ name: 'ads-listing' });
          });
        }
      };
      const onInit = (detail) => {
        lightGallery = detail.instance;
      };
      const updateSlides = () => {
        lightGallery.refresh();
      };
      const onInitDoc = (detail) => {
        lightGalleryDoc = detail.instance;
      };
      const updateSlidesDoc = () => {
        lightGalleryDoc.refresh();
      };
      const previewVideo = (e) => {
        videoInput.value = e.target.files[0];
        const file = e.target.files[0];
        if (file) {
          guideVideo.value = URL.createObjectURL(file);
          updateSlides();
        }
      };
      const previewDoc = (e) => {
        docInput.value = e.target.files[0];
        const file = e.target.files[0];
        if (file) {
          adsPdf.value = URL.createObjectURL(file);
          updateSlides();
        }
      };

      const previewImage = (e) => {
        const file = e.target.files[0];
        thumbnail.value = URL.createObjectURL(file);
      };

      const onSubmitImages = async () => {
        if (submitImagesButton.value) {
          // eslint-disable-next-line
          submitImagesButton.value!.disabled = true;
          submitImagesButton.value.setAttribute('data-kt-indicator', 'on');
        }

        if (!imagesInput.value) {
          return;
        }
        await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');

        const videoFormData = new FormData();
        // eslint-disable-next-line
        for (let i = 0; i < imagesInput.value.length; i++) {
          videoFormData.append('images', imagesInput.value[i]);
        }

        await store.dispatch(Actions.UPLOAD_ADS_IMAGES, {
          data: videoFormData,
          adId: route.params.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          await store.dispatch(
            Actions.REMOVE_BODY_CLASSNAME,
            'page-loading-over'
          );
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitImagesButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitImagesButton.value!.disabled = false;
        } else {
          await store.dispatch(
            Actions.REMOVE_BODY_CLASSNAME,
            'page-loading-over'
          );
          Swal.fire({
            text: translate('SUCCESSFULLY_UPLOADED_AD_IMAGES'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitImagesButton.value?.removeAttribute('data-kt-indicator');
          });
        }
      };

      const onSubmitVideo = async () => {
        if (submitVideoButton.value) {
          // eslint-disable-next-line
          submitVideoButton.value!.disabled = true;
          submitVideoButton.value.setAttribute('data-kt-indicator', 'on');
        }

        if (!videoInput.value) {
          return;
        }
        await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');

        const videoFormData = new FormData();
        // eslint-disable-next-line
        videoFormData.append('video', videoInput.value);

        await store.dispatch(Actions.UPLOAD_ADS_VIDEO, {
          data: videoFormData,
          adId: route.params.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          await store.dispatch(
            Actions.REMOVE_BODY_CLASSNAME,
            'page-loading-over'
          );
          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitVideoButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitVideoButton.value!.disabled = false;
        } else {
          await store.dispatch(
            Actions.REMOVE_BODY_CLASSNAME,
            'page-loading-over'
          );
          Swal.fire({
            text: translate('SUCCESSFULLY_UPLOADED_AD_VIDEO'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitVideoButton.value?.removeAttribute('data-kt-indicator');
          });
        }
      };

      const onSubmitDoc = async () => {
        if (submitDocButton.value) {
          // eslint-disable-next-line
          submitDocButton.value!.disabled = true;
          submitDocButton.value.setAttribute('data-kt-indicator', 'on');
        }

        if (!docInput.value) {
          return;
        }
        await store.dispatch(Actions.ADD_BODY_CLASSNAME, 'page-loading-over');

        const documentFormData = new FormData();
        // eslint-disable-next-line
        documentFormData.append('document', docInput.value);

        await store.dispatch(Actions.UPLOAD_ADS_DOCUMENT, {
          data: documentFormData,
          adId: route.params.id,
        });
        const [errorName] = Object.keys(store.getters.getErrors);
        const error = store.getters.getErrors[errorName];

        if (error) {
          await store.dispatch(
            Actions.REMOVE_BODY_CLASSNAME,
            'page-loading-over'
          );

          Swal.fire({
            text: translate(error[0]),
            icon: 'error',
            buttonsStyling: false,
            confirmButtonText: translate('tryAgainExcl'),
            customClass: {
              confirmButton: 'btn fw-bold btn-light-danger',
            },
          });
          //Deactivate indicator
          submitDocButton.value?.removeAttribute('data-kt-indicator');
          // eslint-disable-next-line
          submitDocButton.value!.disabled = false;
        } else {
          await store.dispatch(
            Actions.REMOVE_BODY_CLASSNAME,
            'page-loading-over'
          );

          Swal.fire({
            text: translate('SUCCESSFULLY_UPLOADED_AD_DOC'),
            icon: 'success',
            buttonsStyling: false,
            confirmButtonText: translate('ok'),
            customClass: {
              confirmButton: 'btn btn-light-primary',
            },
          }).then(() => {
            //Deactivate indicator
            submitDocButton.value?.removeAttribute('data-kt-indicator');
          });
        }
      };

      await store.dispatch(Actions.GET_ALL_AREAS_LIST, { type: 'ads' });

      await store.dispatch(Actions.GET_ALL_AD_TYPES);
      await store.dispatch(Actions.GET_ADS_PACKAGES_LIST);

      await store.dispatch(Actions.REMOVE_BODY_CLASSNAME, 'page-loading-over');

      return {
        submitVideoButton,
        onSubmitVideo,
        onSubmitImages,
        onSubmitUpdate,
        submitButton,
        translate,
        adsSchema,
        goBack,
        can,
        loading,
        appUsers,
        adTypes: computed(() => store.getters.adTypesAllList),
        areas: computed(() => store.getters.allListAreas),
        locations: computed(() => store.getters.organizerLocationsList),
        adsPackages: computed(() => store.getters.adsPackagesList),
        packageId,
        propertyTypesCategories,
        propertyTypes,
        selectedArea,
        selectedAdTypesId,
        selectedPropertyCategoryId,
        selectedPropertyTypesId,
        publishName,
        selectedUser,
        videoInput,
        docInput,
        guideVideo,
        adsPdf,
        getAttachmentName,
        downloadAttachment,
        imagesInput,
        previewVideo,
        previewDoc,
        onInit,
        updateSlides,
        onInitDoc,
        updateSlidesDoc,
        certifiedCheckRequest,
        loanRequest,
        price,
        priceVal,
        adDetails,
        size,
        numRooms,
        numBathrooms,
        previewImage,
        thumbnail,
        onSubmitDoc,
        ad,
        thumbnailImages,
        removeImage,
      };
    },
  });
